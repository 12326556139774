<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<titlesectionList :title="title" :desc1='desc1' :desc2="desc2" :link="link" :linktext="linktext" /> 
         </div>
         <div class="col-lg-12">
            <div class="card">
					<div class="card-header d-flex justify-content-between">
						<div class="header-title">
							<h4 class="card-title">
								<div class="row align-items-center">
									<div class="col-4">
										<b-form-input type="text" class="form-control" list="my-list-id" @keyup.enter="importtextchange($event)" placeholder="업체ID" v-model="업체명"></b-form-input>
										<datalist id="my-list-id">
											<option v-for="companyid in companyids" :key="companyid">{{ companyid }}</option>
										</datalist>
									</div>
									<!-- <div class="col-4"><input type="text" class="form-control" @keyup.enter="importtextchange($event)" placeholder="업체ID" v-model="업체명"></div> -->
									<div class="col-2"><button type="button" :class="importtext=='Connect' ? 'btn btn-primary mr-2' : 'btn btn-success mr-2'" @click="importcompanyinfo">{{importtext}}</button></div>
								</div>
							</h4>
							<!-- <div class="col-md-12" v-if="showspinner">
								<div class="text-center">
									<img style="width:20%;" :src="require('../../../assets/images/small/loading.gif')">
								</div>
							</div> -->
						</div>					
					</div>
					<div class="card-body">
                  <tab-nav :tabs="true" id="myTab-1">
                     <tab-nav-items :active="true" id="exportlist-tab" ariaControls="exportlist" role="tab" :ariaSelected="true" title="출고리스트" />
                     <tab-nav-items :active="false" id="invoice-tab" ariaControls="invoice" role="tab" :ariaSelected="false" title="Invoice/Packing List" />
                  </tab-nav>
                  
                  <tab-content id="myTabContent">
                     <tab-content-item :active="true" id="exportlist" aria-labelled-by="exportlist-tab">
                        <div class="p-3">
                           <div class="row">
                              <div class="col-md-3 mt-2">
                                 <div class="form-group">
                                    <input type="text" class="form-control" placeholder="파렛트바코드" v-model="파렛트바코드">
                                 </div>
                              </div>
                              <div class="col-md-3 mt-2">
                                 <div class="form-group">
                                    <input type="text" class="form-control" placeholder="파렛트번호" v-model="파렛트번호">
                                 </div>
                              </div> 
                              <!-- <div class="col-md-3 mt-2">
                                 <div class="form-group">
                                    <input type="text" class="form-control" placeholder="박스바코드" v-model="박스바코드">
                                 </div>
                              </div> 
                              <div class="col-md-3 mt-2">
                                 <div class="form-group">
                                    <input type="text" class="form-control" placeholder="박스번호" v-model="박스번호">
                                 </div>
                              </div>                                -->
                           </div>     
                           <div class="row">
                              <div class="col-md-4 mt-2">
                                 <button type="button" class="btn btn-primary mr-2 mt-1" @click="search">검색</button>
                                 <!-- <button type="button" class="btn btn-primary mr-2 mt-1" @click="generateReport">generatereport</button> -->
                              </div>
                           </div>     
                        </div>




                        <div class="col-lg-12" v-if="showlist">
                           <form @submit.prevent="submit">
                              <div class="row mt-3">
                                 <div class="col-md-12">
                                    <div class="table-responsive rounded mb-3">
                                       <tableList>
                                          <template v-slot:header >
                                             <tr class="ligth ligth-data">
                                                <th>
                                                   <div class="checkbox d-inline-block">
                                                      <input type="checkbox" class="checkbox-input" id="checkexboxpall" @change="checkexall" v-model="checkexallbox">
                                                      <label for="checkexboxpall" class="mb-0"></label>
                                                   </div>
                                                </th>
                                                <th>업체ID</th>
                                                <th>파렛트번호</th>
                                                <th>파렛트바코드</th>
                                                <th>박스번호</th>
                                                <th>박스바코드</th>                                                
                                                <th>선적출고일시</th>
                                                <th>박스수량</th>
                                                <th>박스내수량</th>
                                                <th>인보이스</th>
                                                <!-- <th>Action</th> -->
                                             </tr>
                                          </template>
                                          <template v-slot:body >
                                             <tr v-for="(list,index) in exportlists" :key="index" >
                                                <td>
                                                   <div class="checkbox d-inline-block">
                                                      <input type="checkbox" class="checkbox-input" id="checkboxp" v-model="list.checked">
                                                      <label for="checkboxp" class="mb-0"></label>
                                                   </div>
                                                </td>
                                                <td v-b-modal="modalID(index)">{{list.업체ID}}</td>
                                                <td v-b-modal="modalID(index)">{{list.type == 'PLT'? list.파렛트번호 : ''}}</td>
                                                <td v-b-modal="modalID(index)">{{list.type == 'PLT'? list.파렛트바코드 : ''}}</td>
                                                <td v-b-modal="modalID(index)">{{list.type == 'BOX'? list.박스번호 : ''}}</td>
                                                <td v-b-modal="modalID(index)">{{list.type == 'BOX'? list.박스바코드 : ''}}</td>
                                                <td v-b-modal="modalID(index)">{{list.선적출고일시}}</td>
                                                <td v-b-modal="modalID(index)">{{list.박스수량}}</td>
                                                <td v-b-modal="modalID(index)">{{list.박스내수량}}</td>
                                                <td><div v-bind:class="badgecolor(list)" @click="showinvoice(list)">{{invoiceinfo(list)}}</div></td>


                                                            <b-modal :id="'modal'+index" scrollable title="박스상세" size="xl" ok-title="OK" cancel-title="Close" class="detail-modal">
                                                               <div class="row">
                                                                  <div class="col-lg-12">
                                                                     <div class="invoice-btn mb-3 ml-3">
                                                                        <button type="button" @click="downloaddetail(list.boxes)" class="btn btn-success active"><i class="las la-file-download"></i>EXCEL</button>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-lg-12">
                                                                     <div class="table-responsive">	
                                                                        <table id="datatable" class="table data-table table-striped dataTable text-center" >
                                                                           <thead>
                                                                              <tr class="ligth ligth-data text-center">
                                                                                 <th>박스번호</th>
                                                                                 <th>바코드</th> 
                                                                                 <th>SKUID</th> 
                                                                                 <th style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">상품명</th> 
                                                                                 <th>출고수량</th> 
                                                                                 <!-- <th>박스내수량</th> -->
                                                                                 <th>발주센터</th>
                                                                                 <th>입고예정일</th>
                                                                                 <th>선적출고일시</th>
                                                                                 <th>박스바코드</th>
                                                                                 <th>파렛트번호</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr class="text-center" v-for="(box,index) in list.boxes" :key="index" >
                                                                                 <td>{{box.박스번호}}</td>
                                                                                 <td>{{box.바코드}}</td>
                                                                                 <td>{{box.SKUID}}</td>
                                                                                 <td style="max-width:200px;overflow:hidden;text-overflow: ellipsis;">{{box.상품명}}</td>
                                                                                 <td>{{box.출고수량}}</td>
                                                                                 <!-- <td>{{box.박스내수량}}</td> -->
                                                                                 <td>{{box.발주센터}}</td>
                                                                                 <td>{{box.입고예정일}}</td>
                                                                                 <td>{{box.선적출고일시}}</td>
                                                                                 <td>{{box.박스바코드}}</td>
                                                                                 <td>{{box.파렛트번호}}</td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </b-modal>

                                             </tr>
                                          </template>
                                       </tableList>
                                    </div>
                                 </div>
                                 <div class="col-md-12" v-if="showlist">
                                    <button class="btn btn-secondary" type="button" @click="createinvoice">Invoice/Packing List 생성</button>
                                    <div class="d-none" v-b-modal.modaldoc ref="click"></div>
                                 </div>		

                                      
                                         
                                             

                                       <b-modal id="modaldoc" size="xl" scrollable title="Invoice/Packing List" ok-title="Invoice/Packing List 생성" cancel-title="취소" class="detail-modal" @ok="saveinvoice">
                                          <div class="row text-center d-flex justify-content-center">
                                             <div class="invoice-btn mb-3 ml-3">
                                                <button type="button" @click="generateReport" class="btn btn-primary active"><i class="las la-file-download"></i>PDF</button>
                                             </div>
                                             <div class="invoice-btn mb-3 ml-3">
                                                <button type="button" @click="generateExcel" class="btn btn-success active"><i class="las la-file-download"></i>EXCEL</button>
                                             </div>
                                             <div class="invoice-btn mb-3 ml-3">
                                                <button type="button" @click="companyinfoinit" class="btn btn-secondary active"><i class="las la-file-download"></i>업체정보수정</button>
                                             </div>                                             
                                          </div>
                                          <div class="d-flex justify-content-center">
                                          <vue-html2pdf
                                             :show-layout="false"
                                             :float-layout="false"
                                             :enable-download="true"
                                             :preview-modal="true"
                                             :paginate-elements-by-height="1400"
                                             filename="Invoice_PackingList"
                                             :pdf-quality="1"
                                             :manual-pagination="true"
                                             pdf-format="a4"
                                             pdf-orientation="portrait"
                                             pdf-content-width="800px"
                                             @hasStartedGeneration="hasStartedGeneration()"
                                             @hasGenerated="hasGenerated($event)"
                                             ref="html2Pdf"
                                          >
                                             <section slot="pdf-content" style="font-size:90%">
                                                <section class="pdf-item">
                                                   <div class="border border-dark p-3">
                                                      <div class="row">
                                                         <div class="col-12 border-bottom">                                  
                                                            <h3 class="mb-2 text-center">COMMERCIAL INVOICE</h3>
                                                         </div>
                                                      
                                                         <div class="col-6 border-right"> 
                                                            <div class="row">
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">Shipper / Exporter</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">WEIHAI FEIYUN INTERNATIONAL TRADE CO., LTD<br>
                                                                     NO. 7-4, BAOYUAN ROAD, WEIHAI ECONOMIC DEVELOPMENT ZONE
                                                                     , SHANDONG PROVICE
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">For Account & Risk of Messrs</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">
                                                                     {{업체ID}}<br>
                                                                     {{업체영문주소}}<br><br>
                                                                     {{업체연락처 != ''? "Tel:+82-" + 업체연락처.slice(1) : ''}}</p>
                                                                  <div class="mb-2" v-if="업체영문주소 == '' || 업체연락처 == ''">
                                                                     <button @click="showsetaddress = true" class="btn btn-secondary">업체정보 입력</button>
                                                                  </div>

                                                                  <div class="mb-2" v-if="(업체영문주소 == '' || 업체연락처 == '') && showsetaddress">
                                                                     <textarea placeholder="업체영문주소 입력" class="form-control mb-2" id="exampleFormControlTextarea1" rows="3" v-model="setaddress"></textarea>
                                                                     <div class="row">
                                                                        <div class="col-7">
                                                                           <input type="text" class="form-control" placeholder="업체연락처 입력" v-model="settel">
                                                                        </div>
                                                                        <div class="col-2">
                                                                           <button @click="saveaddress" class="btn btn-success">save</button>
                                                                        </div>           
                                                                        <div class="col-2">
                                                                           <button @click="cancelsave" class="btn btn-warning">cancel</button>
                                                                        </div>                                                                     
                                                                     </div>
                                                                     
                                                                  </div>




                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">Notify Party</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">
                                                                  </p>    
                                                               </div>
                                                            </div>
                                                            <div class="row">
                                                               <div class="col-6">
                                                                  <div class="row">
                                                                     <div class="col-12 border-bottom mt-2 border-right">
                                                                        <h6 class="mb-2">Port of Loading</h6>
                                                                     </div>
                                                                     <div class="col-12 border-bottom mt-2 border-right">
                                                                        <p class="h6 mb-2">WEIHAI CHINA</p> 
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="col-6">
                                                                  <div class="row">
                                                                     <div class="col-12 border-bottom mt-2">
                                                                        <h6 class="mb-2">Final Destination</h6>
                                                                     </div>
                                                                     <div class="col-12 border-bottom mt-2">
                                                                        <p class="h6 mb-2">INCHEON KOREA</p> 
                                                                     </div>
                                                                  </div>
                                                               </div>                                                            
                                                            </div>
                                                            <div class="row">
                                                               <div class="col-6">
                                                                  <div class="row">
                                                                     <div class="col-12 border-bottom mt-2 border-right">
                                                                        <h6 class="mb-2">Carrier</h6>
                                                                     </div>
                                                                     <div class="col-12 border-bottom mt-2 border-right">
                                                                        <p class="h6 mb-2"></p> 
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="col-6">
                                                                  <div class="row">
                                                                     <div class="col-12 border-bottom mt-2">
                                                                        <h6 class="mb-2">Sailing on/or About</h6>
                                                                     </div>
                                                                     <div class="col-12 border-bottom mt-2">
                                                                        <p class="h6 mb-2"></p> 
                                                                     </div>
                                                                  </div>
                                                               </div>                                                            
                                                            </div>
                                                         </div>
                                                         <div class="col-6"> 
                                                            <div class="row">
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">NO. & Date of Invoice</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">{{today}}
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">L/C No. & Date</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">L/C Issuing Bank</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">Remarks : </h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2"><br><br><br><br><br><br><br><br>
                                                                  </p>    
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="row mt-4">
                                                         <div class="table-responsive-sm col-12">
                                                            <table class="table" id="invoicedoc">
                                                               <thead>
                                                                  <tr>
                                                                     <th class="text-center" scope="col">Mark & Number of</th>
                                                                     <th class="text-center" scope="col">HS-CODE</th>
                                                                     <th class="text-center" scope="col">Description of Goods</th>
                                                                     <th class="text-center" scope="col">Quantity(PCS)</th>
                                                                     <th class="text-center" scope="col">Unit Price</th>
                                                                     <th class="text-center" scope="col">Amount</th>
                                                                  </tr>
                                                               </thead>
                                                               <tbody>
                                                                  <tr class="text-center" v-for="(detail,index) in detaillists" :key="index">
                                                                     <th v-if="detail.파렛트수량>0" :rowspan="detail.파렛트수량"><h6 class="mb-0">{{업체ID}}-{{detail.파렛트번호}}</h6></th>
                                                                     <td class="text-center">{{detail.hscode}}</td>
                                                                     <td class="text-center">{{detail.신고영문명}}</td>
                                                                     <td class="text-center">{{detail.수량}}</td>
                                                                     <td class="text-center">${{detail.신고가격}}</td>
                                                                     <td class="text-center">${{(detail.신고가격*detail.수량).toFixed(1)}}</td>
                                                                  </tr>
                                                               </tbody>
                                                            </table>
                                                         </div>
                                                      </div>
                                                      <b-row class=" mt-4 mb-3">
                                                         <div class="offset-lg-5 col-lg-7">
                                                            <div class="or-detail rounded">
                                                               <div class="ttl-amt py-2 px-3 d-flex justify-content-between align-items-center">
                                                                  <h6>Total</h6>
                                                                  <h3 class="text-primary">{{totalqty}}</h3>
                                                                  <h3 class="text-primary font-weight-700">${{totalprice.toFixed(1)}}</h3>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </b-row> 
                                                   </div>
                                                </section>
                                                <div class="mt-5 html2pdf__page-break"/>
                                                <section class="pdf-item">
                                                   <div class="border border-dark p-3">
                                                      <div class="row">
                                                         <div class="col-12 border-bottom">                                  
                                                            <h3 class="mb-2 text-center">PACKING LIST</h3>
                                                         </div>
                                                      
                                                         <div class="col-6 border-right"> 
                                                            <div class="row">
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">Shipper / Exporter</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">WEIHAI FEIYUN INTERNATIONAL TRADE CO., LTD<br>
                                                                     NO. 7-4, BAOYUAN ROAD, WEIHAI ECONOMIC DEVELOPMENT ZONE
                                                                     , SHANDONG PROVICE
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">For Account & Risk of Messrs</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">
                                                                     {{업체ID}}<br>
                                                                     {{업체영문주소}}<br><br>
                                                                     {{업체연락처 != ''? "Tel:+82-" + 업체연락처.slice(1) : ''}}
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">Notify Party</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">
                                                                  </p>    
                                                               </div>
                                                            </div>
                                                            <div class="row">
                                                               <div class="col-6">
                                                                  <div class="row">
                                                                     <div class="col-12 border-bottom mt-2 border-right">
                                                                        <h6 class="mb-2">Port of Loading</h6>
                                                                     </div>
                                                                     <div class="col-12 border-bottom mt-2 border-right">
                                                                        <p class="h6 mb-2">WEIHAI CHINA</p> 
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="col-6">
                                                                  <div class="row">
                                                                     <div class="col-12 border-bottom mt-2">
                                                                        <h6 class="mb-2">Final Destination</h6>
                                                                     </div>
                                                                     <div class="col-12 border-bottom mt-2">
                                                                        <p class="h6 mb-2">INCHEON KOREA</p> 
                                                                     </div>
                                                                  </div>
                                                               </div>                                                            
                                                            </div>
                                                            <div class="row">
                                                               <div class="col-6">
                                                                  <div class="row">
                                                                     <div class="col-12 border-bottom mt-2 border-right">
                                                                        <h6 class="mb-2">Carrier</h6>
                                                                     </div>
                                                                     <div class="col-12 border-bottom mt-2 border-right">
                                                                        <p class="h6 mb-2"></p> 
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <div class="col-6">
                                                                  <div class="row">
                                                                     <div class="col-12 border-bottom mt-2">
                                                                        <h6 class="mb-2">Sailing on/or About</h6>
                                                                     </div>
                                                                     <div class="col-12 border-bottom mt-2">
                                                                        <p class="h6 mb-2"></p> 
                                                                     </div>
                                                                  </div>
                                                               </div>                                                            
                                                            </div>
                                                         </div>
                                                         <div class="col-6"> 
                                                            <div class="row">
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">NO. & Date of Invoice</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">{{today}}
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">L/C No. & Date</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">L/C Issuing Bank</h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2">
                                                                  </p>    
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <h6 class="mb-2">Remarks : </h6>
                                                               </div>
                                                               <div class="col-12 border-bottom mt-2">
                                                                  <p class="h6 mb-2"><br><br><br><br><br><br><br><br>
                                                                  </p>    
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="row mt-4" id="packinglistdoc">
                                                         <div class="table-responsive-sm col-12">
                                                            <table class="table">
                                                               <thead>
                                                                  <tr>
                                                                     <th class="text-center" scope="col">Mark & Number of</th>
                                                                     <th class="text-center" scope="col">Description of Goods</th>
                                                                     <th class="text-center" scope="col">Quantity(PCS)</th>
                                                                     <th class="text-center" scope="col">G/W't(Kg)</th>
                                                                     <th class="text-center" scope="col">Measur(CBM)</th>
                                                                  </tr>
                                                               </thead>
                                                               <tbody>
                                                                  <tr class="text-center" v-for="(packing,index) in packinglists" :key="index">
                                                                     <th v-if="packing.파렛트수량>0" :rowspan="packing.파렛트수량"><h6>{{업체ID}}-{{packing.파렛트번호}}</h6></th>
                                                                     <td class="text-center">{{packing.신고영문명}}</td>
                                                                     <td class="text-center">{{packing.수량}}</td>
                                                                     <td v-if="packing.weight수량>0" :rowspan="packing.weight수량">{{packing.weight}}</td>
                                                                  <td v-if="packing.cbm수량>0" :rowspan="packing.cbm수량">{{packing.cbm}}</td>
                                                                  </tr>
                                                               </tbody>
                                                            </table>
                                                         </div>
                                                      </div>
                                                      <b-row class=" mt-4 mb-3">
                                                         <div class="offset-lg-4 col-lg-8">
                                                            <div class="or-detail rounded">
                                                               <div class="ttl-amt py-2 px-3 d-flex justify-content-between align-items-center">
                                                                  <h6>Total</h6>
                                                                  <h3 class="text-primary">{{totalboxqty}}{{typeexport}}</h3>
                                                                  <h3 class="text-primary">{{totalweight.toFixed(1)}}</h3>
                                                                  <h3 class="text-primary">{{totalcbm.toFixed(1)}}</h3>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </b-row> 
                                                   </div>
                                                </section>
                                            </section>
                                          </vue-html2pdf> 
                                          </div>   
                                       </b-modal>
                                              
                                          
                                       



                              </div>

                           </form>	
                        </div>



                        
                     </tab-content-item>
                     <tab-content-item :active="false" id="invoice" aria-labelled-by="invoice-tab">
                        <p>Invoice/Packing List</p>

                     </tab-content-item>
                  </tab-content>


               
               </div>
            </div>
         </div>
		</div>
	</div>
</template>
<script>
// import { mapState } from 'vuex';
import axios from 'axios';
import * as XLSX from 'xlsx';
// import VueHtml2pdf from 'vue-'
// import { core } from '../../../config/pluginInit'
export default {
    name:'logisimportlist',
    data(){
        return{
            업체명: '',
            importtext : 'Connect',
            companyids : [],



            dbs : [],
            
            showsetaddress:false,
            setaddress:'',
            settel:'',

            업체ID:'',
            업체영문주소:'',
            업체연락처:'',
            업체영문주소init:'',
            업체연락처init:'',            
            detaillists : [],
            packinglists : [],
            totalqty: 0,
            totalprice: 0,
            totalboxqty: 0,
            totalweight: 0,
            totalcbm: 0,
            typeexport:'PLT',

            title:"출고리스트(Invoice list)",
            desc1:"출고리스트는 변경이 불가합니다.",
            desc2:"",
            link:{ name: 'logis.logisaddimport'},
            linktext:"출고등록",
            badge : 'badge',


            today : this.getTodaywith(),
            // startdate: this.getstartdate(),
            // enddate: this.getTodaywith(),
            박스바코드: '',
            파렛트바코드: '',
            박스번호: '',
            파렛트번호: '',

            showlist: false,
            checkexallbox: false,
            exportlists:[],

        }
    },
    mounted(){
      if(this.$store.state.productdb.length > 0){
			this.업체명 = this.$store.state.productdb[0].companyid;
			this.importtext = 'Connected';
		}
		this.importcompanyids()
    },	
    methods : {
      importcompanyids(){
			axios.post('/api/getcompanyids',{})
            .then( result => {
				if (result.data.요청결과 == 'success'){
					result.data.result.forEach(e => {
						if(e.auth.showimport){
                            if(!this.companyids.includes(e.companyid)){
                                this.companyids.push(e.companyid);
                            }
                        }
					})
				} else {
					alert(result.data.사유)
				}
            }).catch((e)=>{
                alert('companyid 가져오기 실패 (1413)')
                console.log(e);
                return true
            })
		},
      importtextchange(event){
			this.importtext = 'Connect';
			if(event.keyCode === 13 && this.업체명 != ''){
				this.importcompanyinfo()
			}
		},
      async importcompanyinfo(){
			if(this.importtext == 'Connect'){
				if(this.업체명){
					if(this.companyids.find(e => e == this.업체명)){
						if(this.$store.state.purchase.length > 0 && this.업체명.toUpperCase() == this.$store.state.purchase[0].companyid.toUpperCase()){
							// this.showspinner = false;
							this.importtext = 'Connected'
							return false
						}
						this.showspinner = true;
						return axios.post('/api/logisaddimport/importcompanyinfo',{
							companyid : this.업체명
						})
						.then( result => {
							if (result.data.요청결과 == 'success') {
								console.log(result.data)
								this.$store.commit('dbupdate', result.data.dbs)
								// this.importstock = this.$store.state.stock.length > 0 ? this.$store.state.stock.filter(e => e.site == 'cn') : [];
								// this.showspinner = false;
								this.importtext = 'Connected';
								
								return true
							} else {
								alert('업체 Data Import 실패')
								return false
							}
						}).catch((e)=>{
							console.log(e);
							return false
						})
					} else {
						alert('존재하지 않은 업체ID 입니다.')
					}
				} else {
					alert('업체ID를 기입해 주세요')
				}
			} else {
				var obj = {
					polist : [],
					productdb : [],
					purchase : [],
					returns : [],
					exportlist : [],
					stock : [],
               reqpurchase : [],
				}
				this.$store.commit('dbupdate',obj);
				this.importstock = [];
				this.importtext = 'Connect';
				this.업체명 = '';
				this.showpurchaselist = false;
				this.showlist = false;
				this.showfindlist = false;
				this.showarrangebox = false;
				this.showstockbox = false;
				this.showspinner = false;
				this.showimporterror = false;
				this.showchecklist = false;
				return false
			}
		},
      downloaddetail(arr){
         const workBook = XLSX.utils.book_new()
         const workSheet1 = XLSX.utils.json_to_sheet(arr)
         XLSX.utils.book_append_sheet(workBook, workSheet1, '출고상세내역')
         XLSX.writeFile(workBook, '출고상세내역' + '_' + '.xlsx')
      },
      async showinvoice(list){
         if(list.invoice > 0){
            this.showsetaddress = list.invoicelist.showsetaddress;
            this.setaddress = list.invoicelist.setaddress;
            this.settel = list.invoicelist.settel;
            this.업체ID = list.invoicelist.업체ID;
            this.업체영문주소 = list.invoicelist.업체영문주소;
            this.업체연락처 = list.invoicelist.업체연락처;
            this.업체영문주소init = list.invoicelist.업체영문주소init;
            this.업체연락처init = list.invoicelist.업체영문주소init;
            this.detaillists  = list.invoicelist.detaillists;
            this.packinglists  = list.invoicelist.packinglists;
            this.totalqty = list.invoicelist.totalqty;
            this.totalprice = list.invoicelist.totalprice;
            this.totalboxqty = list.invoicelist.totalboxqty;
            this.totalweight = list.invoicelist.totalweight;
            this.totalcbm = list.invoicelist.totalcbm;
            this.typeexport = list.invoicelist.typeexport;
            this.$refs.click.click();
         } else {
            this.exportlists.forEach(e => {
               e.checked = false;
            })
            setTimeout(async () => {
               list.checked = true;
               await this.createinvoice()
            },10)
         }
      },
      async saveinvoice(){
         this.showlist = false;
         this.exportlists.filter(e => e.checked).forEach(e => {
            e.invoice = 1
            e.invoicelist = {
               showsetaddress:this.showsetaddress,
               setaddress:this.setaddress,
               settel:this.settel,
               업체ID:this.업체ID,
               업체영문주소:this.업체영문주소,
               업체연락처:this.업체연락처,
               업체영문주소init:this.업체영문주소init,
               업체연락처init:this.업체영문주소init,
               detaillists :this.detaillists,
               packinglists :this.packinglists,
               totalqty:this.totalqty,
               totalprice: this.totalprice,
               totalboxqty:this.totalboxqty,
               totalweight:this.totalweight,
               totalcbm:this.totalcbm,
               typeexport:this.typeexport,
            }
         })
         axios.post('/api/logisimportlist/updateinvoice',this.exportlists)
         .then((res) => {
            if(res.data.요청결과 == "업데이트 완료"){
               alert('인보이스 생성 완료')
               this.showlist = true;
            } else {
               alert('업데이트 실패(639)')
            }
         })
         .catch(e => {
            alert('업데이트 실패(642)');
            console.log(e)
         })
      },
      companyinfoinit(){
         this.setaddress = this.업체영문주소;
         this.settel = this.업체연락처;
         this.업체영문주소 = '';
         this.업체연락처 = '';
         this.showsetaddress = true;
      },
      saveaddress(){
         if(confirm('업체 정보를 업데이트 하시겠습니까?')){
            axios.post('/api/addpurchase/addsettings',{
               companyid:this.exportlists[0].업체ID,
               address:this.setaddress,
               tel:this.settel
            })
            .then((res) => {
               if(res.data.요청결과 == "Settings 변경완료"){
                  this.업체영문주소 = this.setaddress;
                  this.업체연락처 = this.settel;
                  alert('업데이트 완료')
               } else {
                  alert('업데이트 실패(560)')
               }
            })
            .catch(console.log)
         }
      },
      cancelsave(){
         this.업체영문주소 = this.업체영문주소init;
         this.업체연락처 = this.업체연락처init;
         this.showsetaddress = false;
      },
      invoiceinfo(list){
         if(list.invoice > 0){
            return "Created"
         } else {
            return "Not created"
         }
      },
      async createinvoice(){
         if(this.exportlists.filter(e => e.checked).length == 0){
            alert('Invoice/Packing List를 생성할 목록을 체크해 주세요')
            return
         }
         if(this.exportlists.filter(e => e.checked && e.업체ID == this.exportlists.find(e => e.checked).업체ID).length != this.exportlists.filter(e => e.checked).length){
            alert('동일한 업체ID만 체크해 주세요.')
            return
         }
         if(this.exportlists.filter(e => e.checked)[0].업체ID){
            if(this.exportlists.filter(e => e.checked)[0].업체ID.length > 0){
               this.업체ID = this.exportlists.filter(e => e.checked)[0].업체ID.toUpperCase();
            } else {
               this.업체ID = '';   
            }
         } else {
            this.업체ID = '';
         }
         // if(this.exportlists[0].업체영문주소){
         //    if(this.exportlists[0].업체영문주소.length > 0){
         //       this.업체영문주소 = this.exportlists[0].업체영문주소.toUpperCase();
         //    } else {
         //       this.업체영문주소 = '';   
         //    }
         // } else {
         //    this.업체영문주소 = '';
         // }
         // if(this.exportlists[0].업체연락처){
         //    if(this.exportlists[0].업체연락처.length > 0){
         //       this.업체연락처 = this.exportlists[0].업체연락처;
         //    } else {
         //       this.업체연락처 = '';   
         //    }
         // } else {
         //    this.업체연락처 = '';
         // }
         var obj = {};
         this.totalqty = 0;
         this.totalprice = 0;
         this.detaillists = [];
         this.packinglists = [];
         var companyid = this.exportlists.filter(e => e.checked)[0].업체ID;
         
         var settings = this.dbs.find(e => e.companyid == companyid).settings;
         console.log(settings)
         this.업체영문주소 = settings.address ? settings.address : "";
         this.업체연락처 = settings.tel ? settings.tel : "";
         this.업체영문주소init = settings.address ? settings.address : "";
         this.업체연락처init = settings.tel ? settings.tel : "";         
         var hscode = '';
         var cnt = 0;
         var name = '';
         var price = 0;
         this.totalboxqty = this.exportlists.filter(e => e.checked).length;
         this.totalweight = this.exportlists.filter(e => e.checked).reduce((pv,cv) => {return pv + parseFloat(cv.gw)},0);
         this.totalcbm = this.exportlists.filter(e => e.checked).reduce((pv,cv) => {return pv + parseFloat(cv.cbm)},0);
         this.typeexport = this.exportlists.filter(e => e.checked)[0].type;

         this.exportlists.filter(ele => ele.checked).forEach(ele => {
            console.log(ele)
            ele.boxes.forEach(element => {
               hscode = element.hscode;
               cnt = element.출고수량;
               name = element["품명(영문)"];
               price = element.price;
               if(this.detaillists.length > 0){
                  if(this.detaillists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase() && f.hscode == hscode && f.신고영문명 == name && f.신고가격 == price)){
                     this.detaillists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase() && f.hscode == hscode && f.신고영문명 == name && f.신고가격 == price).수량 += cnt;
                     this.totalqty += cnt;
                     this.totalprice += price*cnt
                  } else {
                     if(this.detaillists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase())){
                        this.detaillists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase()).파렛트수량++
                        obj = {
                           파렛트번호:ele.파렛트번호.toUpperCase(),
                           hscode:hscode,
                           신고영문명:name,
                           수량:cnt,
                           신고가격:price,
                           파렛트수량:0
                        }
                        this.totalqty += cnt;
                        this.totalprice += price*cnt
                        this.detaillists.push(obj)
                     } else {
                        obj = {
                           파렛트번호:ele.파렛트번호.toUpperCase(),
                           hscode:hscode,
                           신고영문명:name,
                           수량:cnt,
                           신고가격:price,
                           파렛트수량:1
                        }
                        this.totalqty += cnt;
                        this.totalprice += price*cnt
                        this.detaillists.push(obj)
                     }
                  }
               } else {
                  obj = {
                     파렛트번호:ele.파렛트번호.toUpperCase(),
                     hscode:hscode,
                     신고영문명:name,
                     수량:cnt,
                     신고가격:price,
                     파렛트수량:1
                  }
                  this.totalqty += cnt;
                  this.totalprice += price*cnt
                  this.detaillists.push(obj)
               }
               if(this.packinglists.length > 0){
                  if(this.packinglists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase() && f.신고영문명 == name)){
                     this.packinglists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase() && f.신고영문명 == name).수량 += cnt;
                     this.typeexport = ele.type;
                  } else {
                     if(this.packinglists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase())){
                        this.packinglists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase()).파렛트수량++
                        this.packinglists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase()).weight수량++
                        this.packinglists.find(f => f.파렛트번호.toUpperCase() == element.파렛트번호.toUpperCase()).cbm수량++

                        obj = {
                           파렛트번호:ele.파렛트번호.toUpperCase(),
                           신고영문명:name,
                           수량:cnt,
                           weight:ele.gw,
                           cbm:ele.cbm,
                           파렛트수량:0,
                           weight수량:0,
                           cbm수량:0,
                        }
                        this.packinglists.push(obj)
                     } else {
                        obj = {
                           파렛트번호:ele.파렛트번호.toUpperCase(),
                           신고영문명:name,
                           수량:cnt,
                           weight:ele.gw,
                           cbm:ele.cbm,
                           파렛트수량:1,
                           weight수량:1,
                           cbm수량:1,                                 
                        }
                        this.packinglists.push(obj)
                     }
                  }
               } else {
                  obj = {
                     파렛트번호:ele.파렛트번호.toUpperCase(),
                     신고영문명:name,
                     수량:cnt,
                     weight:ele.gw,
                     cbm:ele.cbm,
                     파렛트수량:1,
                     weight수량:1,
                     cbm수량:1,                           
                  }
                  this.packinglists.push(obj)
               }
                  
            })
         })  
         console.log(this.detaillists)
         console.log(this.packinglists)
         this.$refs.click.click();
      },
      generateReport () {
         this.$refs.html2Pdf.generatePdf()
      },
      generateExcel(){
         var invoice = XLSX.utils.table_to_sheet(document.getElementById('invoicedoc'));
         var packinglist = XLSX.utils.table_to_sheet(document.getElementById('packinglistdoc'));
         var workBook = XLSX.utils.book_new();
         XLSX.utils.book_append_sheet(workBook, invoice, 'Invoice');
         XLSX.utils.book_append_sheet(workBook, packinglist, 'PackingList');
         XLSX.writeFile(workBook, 'Invoice_PackingList.xlsx');
      },
      checkexall(){
         if(this.checkexallbox){
            this.exportlists.forEach(e => e.checked = true)
         } else {
            this.exportlists.forEach(e => e.checked = false)
         }
      },
      async search(){
         this.exportlists = [];
         if(this.업체명 == '' || this.importtext == 'Connect'){
            alert('업체ID를 먼저 Connect해주세요')
            return
         }
         this.showlist = false;
         var arr = [];
         var obj = {};
         var ar = [];
         var products = [];
         var ob = {};
         if(this.$store.state.invoice.length == 0){
            alert('출고리스트가 없습니다.')
            return
         }
         this.dbs = [];
         console.log(this.$store.state.invoice)
         for(var e of this.$store.state.invoice){
            if(this.dbs.length == 0 || this.dbs.filter(ele => ele.companyid && ele.companyid == e.companyid).length == 0){
               // var result = await axios.post('/api/logisimportlist/importstock',{companyid:e.companyid})
               // console.log(result)
               this.dbs.push({
                  companyid : this.업체명,
                  stocklists : this.$store.state.stock,
                  productdb : this.$store.state.productdb,
                  settings : this.$store.state.settings,
                  reqpurchase : this.$store.state.reqpurchase,
               })
            }
            var stocklists = this.dbs.find(ele => ele.companyid == e.companyid).stocklists;
            var productdb = this.dbs.find(ele => ele.companyid == e.companyid).productdb;
            var reqpurchase = this.dbs.find(ele => ele.companyid == e.companyid).reqpurchase;
            products = [];
            e.boxes.forEach(ele => {
               ar.push(this.deepClone(ele))
            })
            ar.forEach(ele => {
               ele.파렛트번호 = e.pallet;
               ele.선적출고일시 = e.선적출고일시;
               ele.파렛트바코드 = e.companyid.slice(0,2)+'cnex'+e.pallet;
               ele.박스바코드 = e.companyid.slice(0,2)+'cn'+ele.입고예정일.replaceAll("-","").slice(4)+ele.박스번호;
               stocklists.filter(
                  element => element.site == 'cn2kr' &&
                  element.선적출고일시 == e.선적출고일시 && 
                  element.배치박스번호 == ele.박스번호 && 
                  element.배치입고예정일 == ele.입고예정일 && 
                  element.배치파렛트번호 == e.pallet &&
                  element.배치발주센터 == ele.발주센터
               ).forEach(element => {
                  // console.log(element)
                  // console.log(products.filter(f => f.바코드 == element.바코드))
                  if(products.length > 0 && 
                     products.find(f => f.상품명 == element.상품명 && f.선적출고일시 == element.선적출고일시 && f.발주번호 == element.배치발주번호 && f.박스번호 == element.배치박스번호)
                  ){
                     products.find(f => f.상품명 == element.상품명 && f.선적출고일시 == element.선적출고일시 && f.발주번호 == element.배치발주번호 && f.박스번호 == element.배치박스번호).출고수량++
                  } else {
                     if(!productdb.find(f => f.상품명 == element.상품명)){
                        ob = {
                           바코드 : element.바코드,
                           상품명 : element.상품명,
                           SKUID : element.SKUID,
                           "품명(한글)" : element.상품명,
                           "품명(영문)" : reqpurchase.find(f => f.상품명 == element.상품명)?.신고영문명 ? reqpurchase.find(f => f.상품명 == element.상품명)?.신고영문명 : '',
                           출고수량 : 1,
                           박스번호 : ele.박스번호,
                           박스바코드 : ele.박스바코드,
                           파렛트번호 : e.pallet,
                           파렛트바코드 : ele.파렛트바코드,
                           발주번호 : element.배치발주번호,
                           발주센터 : element.배치발주센터,
                           입고예정일 : element.배치입고예정일,
                           선적출고일시 : element.선적출고일시,
                           hscode : reqpurchase.find(f => f.상품명 == element.상품명)?.HSCODE ? reqpurchase.find(f => f.상품명 == element.상품명)?.HSCODE : '',
                           price : reqpurchase.find(f => f.상품명 == element.상품명)?.신고가격 ? reqpurchase.find(f => f.상품명 == element.상품명)?.신고가격 : '',
                        }
                     } else {
                        ob = {
                           바코드 : element.바코드,
                           상품명 : element.상품명,
                           SKUID : element.SKUID,
                           "품명(한글)" : productdb.find(f => f.상품명 == element.상품명).제품분류 ? productdb.find(f => f.바코드 == element.바코드).제품분류 : "정보없음(상품DB update필요)",
                           "품명(영문)" : productdb.find(f => f.상품명 == element.상품명).신고영문명 ? productdb.find(f => f.바코드 == element.바코드).신고영문명 : "정보없음(상품DB update필요)",
                           출고수량 : 1,
                           박스번호 : ele.박스번호,
                           박스바코드 : ele.박스바코드,
                           파렛트번호 : e.pallet,
                           파렛트바코드 : ele.파렛트바코드,
                           발주번호 : element.배치발주번호,
                           발주센터 : element.배치발주센터,
                           입고예정일 : element.배치입고예정일,
                           선적출고일시 : element.선적출고일시,
                           hscode : productdb.find(f => f.바코드 == element.바코드)['HS-CODE'] ? productdb.find(f => f.바코드 == element.바코드)['HS-CODE'] : "정보없음(상품DB update필요)",
                           price : productdb.find(f => f.바코드 == element.바코드)['신고가격(USD)'] ? productdb.find(f => f.바코드 == element.바코드)['신고가격(USD)'] : "정보없음(상품DB update필요)",
                        }
                     }
                     products.push(ob)
                  }
               })
            })
            
            obj = {
               업체ID : e.companyid,
               checked : false,
               boxes : products,
               파렛트번호 : e.pallet,
               박스번호 : e.pallet,
               파렛트바코드 : e.companyid.slice(0,2)+'cnex'+e.pallet,
               박스바코드 : e.companyid.slice(0,2)+'cnex'+e.pallet,
               선적출고일시 : e.선적출고일시,
               invoice : e.invoice,
               박스수량 : e.boxes.length,
               박스내수량 :e.boxes.reduce((pv,cv) => {return pv + cv.박스내수량},0),
               gw : e.gw,
               cbm : e.cbm,
               type : e.type,
               _id : e._id,
               invoicelist : e.invoicelist ? e.invoicelist : {},
            }
            arr.push(obj)
         }

         if(this.파렛트바코드.length > 0){
            arr = arr.filter(e => e.파렛트바코드 == this.파렛트바코드)
         }
         if(this.파렛트번호.length > 0){
            arr = arr.filter(e => e.파렛트번호 == this.파렛트번호)
         }
         // if(this.박스바코드.length > 0){
         //    arr = arr.filter(e => e.박스바코드 == this.박스바코드)
         // }
         // if(this.박스번호.length > 0){
         //    arr = arr.filter(e => e.박스번호 == this.박스번호)
         // }
         arr.sort(function(a,b){
            return new Date(b.선적출고일시) - new Date(a.선적출고일시);
         })
                     
         this.exportlists = arr;
         // console.log(arr)
         setTimeout(() => {
            this.showlist = true;
         }, 10);
      },
      
      maxvalue(data,key){
			return data.reduce((prev, current) => (prev[key] > current[key]) ? prev : current)[key]
		},  
      getTodaywith(){
         var date = new Date();
         var year = date.getFullYear();
         var month = ("0" + (1 + date.getMonth())).slice(-2);
         var day = ("0" + date.getDate()).slice(-2);

         return year + "-" + month + "-" + day;
      },  
      getstartdate(){
         var date = new Date();
         var year = date.getFullYear()-1;
         var month = ("0" + (1 + date.getMonth())).slice(-2);
         var day = ("0" + date.getDate()).slice(-2);

         return year + "-" + month + "-" + day;
      },        
      modalID(index) {
         return 'modal' + index
      },
      modalIDedit(index) {
         return 'modaledit' + index
      },      
      deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}
			const result = Array.isArray(obj) ? [] : {}
			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}
			return result
      },
      changestockinfo(stockinfo){
         if(stockinfo == "stock") {
            return "재고"
         }
         if(stockinfo == "Astock") {
            return "배치재고"
         }
         if(stockinfo == "Pstock") {
            return "구매재고"
         }
         if(stockinfo == "Rstock") {
            return "회송재고"
         }                      
      },
      changeboxinfo(stockinfo,재고박스번호,배치박스번호){
         if(stockinfo == "stock") {
            return 재고박스번호
         }
         if(stockinfo == "Astock") {
            return 배치박스번호
         }
         if(stockinfo == "Pstock") {
            return "구매입고전"
         }
         if(stockinfo == "Rstock") {
            return "회송입고전"
         } 
      },
      changepalletinfo(stockinfo,재고파렛트번호,배치파렛트번호) {
         if(stockinfo == "stock") {
            return 재고파렛트번호
         }
         if(stockinfo == "Astock") {
            return 배치파렛트번호
         }
         if(stockinfo == "Pstock") {
            return "구매입고전"
         }
         if(stockinfo == "Rstock") {
            return "회송입고전"
         }            
      },
      badgecolor(list){
         if(list.invoice > 0) {
            return "badge badge-success"
         } else {
            return "badge badge-primary"
         }
      },
      timestamp(date){
         //inputdate = "yyyy-mm-dd"
         return new Date(date).getTime() / 1000
      },
      download() {
         var arr = [];
         if(this.stocklists.filter(e => e.checked).length == 0){
            arr = this.stocklists
         } else {
            arr = this.stocklists.filter(e => e.checked)
         }
         function getToday(){
               var date = new Date();
               var year = date.getFullYear();
               var month = ("0" + (1 + date.getMonth())).slice(-2);
               var day = ("0" + date.getDate()).slice(-2);

               return year + month + day;
         }

         const workBook = XLSX.utils.book_new()
         const workSheet1 = XLSX.utils.json_to_sheet(arr)
         XLSX.utils.book_append_sheet(workBook, workSheet1, '재고현황')
         XLSX.writeFile(workBook, '재고현황' + '_' + getToday() + '.xlsx')
      }
    }
}
</script>
<style scoped>
.modal-dialog {
    max-height: 80%;
    position: absolute; 
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
